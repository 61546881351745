$blue:#2674ED;
$cta-light-primary-hover:#2261C2;
$blue-light:#C8D8F0;
$stroke-light-secondary:#D4E3FB;
$my-gradient:linear-gradient(90deg, #D22D1E -0.08%, #963AB1 52.26%, #20469B 99.82%);
$background-dark-primary:#151316;
$background-dark-secondary:#1A181B;
$background-dark-tertiary:#272528;
$text-light-primary:#383838;
$text-dark-tertiary:#8E8E8E;
$stroke-dark:#3C3A3D;
$cta-dark-primary:#1E1C1F;
$white:#fff;
$white-10:#F6F6F6;
$text-dark-secondary:#DEDEDE;
$troke-light-primary:#D4E3FB;
$green:#85D992;
$green-20:#286F30;
$red:#F04438;
$orange:#F9C823;
$background-blue:#E7F0FD;

.background-dark-primary{
    background-color: $background-dark-primary;
}

.bg-green {
    background-color: $green !important;
    background: $green !important;
}

.green-text {
    color: $green-20 !important;
}

.green-text-light {
    color: $green !important;
}

.background-dark-secondary{
    background-color:$background-dark-secondary !important;
}

.background-dark-tertiary{
    background: $background-dark-tertiary !important;
}

.text-dark-tertiary{
    color: $text-dark-tertiary !important;
}

.text-dark-secondary{
    color: $text-dark-secondary !important;
}

.text-light-primary {
    color: $text-light-primary !important;
}

.blue{
   color:$blue !important;
}

.warning-danger{
    color: $red !important;
}

.text-orange{
    color: $orange;
}

.bg-blue-light{
    background-color:$blue-light;
}

.background-light-secondary{
    background-color: $white-10 !important;
}

.background-blue{
    background-color: $background-blue;
}

.bg-black {
    background-color: #000000;
}