.bb-sprite {
	background-image: url('https://imgcdn.analyticsvidhya.com/blackbelt/Events_page_logos.png');
    background-repeat:no-repeat;
	width: 160px;
	height: 60px;
}

.bb-sprite2 {
	background-image: url('https://d26nt4befnrop2.cloudfront.net/blackbelt/sprite_image.png?format=webp');
    background-repeat:no-repeat;
	width: 160px;
	height: 60px;
}

.genai-sprite{
	width: 160px;
	height: 60px;
    background-repeat: no-repeat;
    background:url("https://d2cd20fxv8fgim.cloudfront.net/genaipinnacle/Sprite logo's.svg?format=webp");
    background-size: 600%;
}

.fractal{   
    background-position:-2px -0px;
}
    
.tiger-analytics{
    background-position:-188px -0px;
}

.paypal{
    background-position:-375px -0px;
}

.zs{
    background-position:-559px -0px;
}

.wns{
    background-position:-746px -0px;
}
    
.kotak{
    background-position: -2px -81px;
}

.zee{
    background-position:-187px -82px;
}

.gramener{
    background-position:-374px -82px ;
}

.ericsson{
    background-position:-564px -82px;
}

.centric{
    background-position:-744px -82px;
}

.brillio{
    background-position:-744px -162px;
}

.h2o{
    background-position:-559px -162px;
}

.affine{
    background-position:-374px -162px;
}

.latentview{
    background-position:-188px -162px;
}

.konvergeai{
    background-position:-3px -162px;
}

.python{
    background-position:-50px -417px;
}

.numpy{
    background-position:-606px -419px;
}

.rasa{
    background-position:-50px -580px;
}

.dask{
    background-position:-235px -500px;
}

.featuretool{
    background-position:-607px -580px;
}

.streamlit{
    background-position:-605px -500px;
}

.aws{
    background-position:-235px -418px;
}

.excel{
    background-position:-792px -580px;
}

.flask{
    background-position:-421px -581px;
}

.keras{
    background-position:-421px -499px;
}

.matplotlib{
    background-position:-792px -499px;
}

.pandas{
    background-position:-421px -418px;
}

.spacy{
    background-position:-235px -662px;
}

.powerbi{
    background-position:-792px -419px;
}

.pytorch{
    background-position:-50px -500px;
}

.scikitlearn{
    background-position:-236px -580px;
}

.spark{
    background-position:-606px -661px;
}

.tableau{
    background-position:-421px -661px;
}

.sql{
    background-position:-50px -661px;
}

.adobe-firefly{
    background-position: -8px -119px;
}

.blue-willow{
    background-position: -201px -119px;
}


// Events
.event-meta{
    background-position:-13px -1185px;
}

.event-nvidia{
    background-position:-199px -1185px;
}

.event-phonepe{
    background-position:-385px -1185px;
}

.event-myntra{
    background-position:-569px -1185px;
}

.event-swiggy{
    background-position:-755px -1185px;
}

.event-cred{
    background-position:-13px -1265px;
}

.event-paytm{
    background-position:-186px -1020px;
}

.event-microsoft{
    background-position:-382px -1265px;
}

.event-mit{
    background-position:-571px -1265px;
}

.event-siemens{
    background-position:-755px -1265px;
}

.event-weights{
    background-position:-13px -1346px;
}

.event-bosch{
    background-position:-199px -1346px;
}

.event-uber{
    background-position:-382px -1346px;
}

.event-aws{
    background-position:-188px -370px;
}

.event-adobe{
    background-position:-755px -1346px;
}

.event-oracle{
    background-position:-13px -1427px;
}

.event-jaguar{
    background-position:-199px -1427px;
}

.event-ibm{
    background-position:-382px -1427px;
}

.event-bain{
    background-position:-571px -1427px;
}

.event-paypal{
    background-position:-755px -1427px;
}

.event-cohere{
    background-position:-13px -1510px;
}

.event-visa{
    background-position:-199px -1510px;
}

.event-salesforce{
    background-position:-382px -1510px;
}

.event-h2o{
    background-position:-571px -1510px;
}

.event-mckinsey{
    background-position:-755px -1510px;
}

.event-publicsapient{
    background-position:-13px -1592px;
}

.event-opencv{
    background-position:-199px -1592px;
}

.event-pfizer{
    background-position:-382px -1592px;
}

.event-amazon{
    background-position:-571px -1592px;
}

.event-google{
    background-position:-755px -1592px;
}

.event-apple{
    background-position:-13px -1674px;
}

.event-lightning{
    background-position:-199px -1674px;
}

.event-huggingface{
    background-position:-382px -1674px;
}