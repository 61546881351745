.fractal-logo{
    svg{
        @media (max-width:599px) {
            width: 120px;
        }
    }
}
#personalised.fractal-personalised{
    ul {
        padding-left:2rem !important;
        li{
            list-style:disc !important;
        }
    }
}

.collapse-no-visible[aria-expanded=true]{
    display: none !important;
}

a.disabled {
    pointer-events: none;
    cursor: default;
}
  