#heroFold{
    // background-image: url('https://d26nt4befnrop2.cloudfront.net/free_courses/gradient.png?format=webp&width=424');
    // background-repeat: no-repeat;
    // background-position: top left;
    // background-size: cover;
    padding-top: 70px;
    h1{
        word-break: break-word !important;

        @media (max-width:599px) {
            font-size: 36px !important;
        }
    }

    .border-dark{
        border-color:$stroke-dark !important;
    }

    .col-lg-8{
        padding-bottom: 120px !important;

        @media (max-width:599px) {
            padding-bottom: 24px !important;
        }
    }
}

#stickyHeader{
    &.active{
        background-color: $background-dark-secondary !important;
        z-index: 9 !important;
        ul{
            border: 0px;
        }
    }
 ul{
    border: 1px solid $stroke-dark;
    li{
        .active{
            color: $white !important;
            font-weight:  $font-size-regular;
            border-bottom: 1px solid $white;
        }
    }
 }
}

#coursesDetail{
    padding: 60px 0px 120px 0px;

    .courses-card{
        background: linear-gradient(180deg, rgba(38, 56, 89, 0.20) 0%, rgba(31, 46, 73, 0.16) 20.83%, rgba(0, 0, 0, 0.00) 100%), #1A181B;
        border: 1px solid $stroke-dark;
        top: 140px;
    }

    #courseHighlights{
        .card-header{
            border-bottom: 1px solid $stroke-dark;
        }

        .course-points{
            height: 200px;
            overflow: hidden;
            transition: all 0.3 ease-in-out;
            .overlay{
                background-image: linear-gradient(transparent 0%, $background-dark-secondary 95%);
            }

            &.active{
                height: fit-content;
                .overlay{
                    background-image: none !important;
                }
            }
        }

        ul{
            li{
                position: relative;
                padding-left: 40px;
                &::before{
                    content: '';
                    width: 10px;
                    height: 20px;
                    border-width:2px;
                    border-color: transparent white white transparent;
                    border-style: solid;
                    position:absolute;
                    left: 10px;
                    top: calc(50% - 15px);
                    transform: rotate(45deg);
                }
            }
        }
    }

    .accordion {
        height: 457px;
        overflow-y: auto;
        .accordion-item {
            background-color: transparent !important;
            background: transparent !important;
        }

        .accordion-body {
            height: 190px;
            overflow-y: auto;
        }
    
        .accordion-button {
            background-color: $background-dark-tertiary !important;
            padding: 16px 24px;
            &:focus {
                box-shadow: none !important;
            }
            
            &::after{
                background-image: url('https://d2cd20fxv8fgim.cloudfront.net/free_courses/down.svg');
            }
        }
        .accordion-button:not(.collapsed) {
            box-shadow: none !important;
        }

        h4 {
            line-height: 24px;
        }

        ol {
            li {
                list-style: decimal !important;
                color: $text-dark-tertiary;
                margin-bottom: 16px;
                font-size: $font-size-regular;
                font-weight: 300;
                transition: all .5s;
                &:hover{
                    color: $white;
                }
            }
        }
    }

    #courseCurriculum{
        .btn{
            @media (max-width:599px) {
                width: 100%;
            }
        }

        ol li{
            list-style: none !important;
        }
    }
    
}

#hearFromOurCommunity{
    padding-bottom: 120px;

    .custom-quote{
        padding-top: 50px;
        height: fit-content !important;
    }
}

#relatedCourses{
    padding-bottom: 120px;
}

#popularCategory{
    padding: 60px;

    .btn{
        padding:16px !important;
        @media (max-width:599px) {
            padding: 8px 12px !important;
        }
        border-color:transparent;
        background-color: $background-dark-tertiary !important;
        background: $background-dark-tertiary !important;

        &:hover{
            border-color:$stroke-dark;
        }
    }
}

#seoFooter{
    padding: 60px;   
    border-bottom: 1px solid $text-dark-tertiary;
}

.dropdown-menu{
    z-index: 9999;
    left: auto !important;
    right: 0 !important;
}

.flashstrip{
    background:$my-gradient;
}

.breadcrumb-item{
    &::before{
        color:$text-dark-tertiary !important;
    }
}

#faq .accordion {
    .accordion-header button {
        padding: 32px 40px;
        &::after{
            background-image: url('https://d2cd20fxv8fgim.cloudfront.net/free_courses/down.svg') !important;
            filter:invert(0) !important;
        }
    }

    .accordion-body{
        padding: 15px 40px;
    }
}