#search{
    height: 100vh;
    background-image:url('https://www.analyticsvidhya.com/static/media/hero-image-bb.a132de9e5cd9abaef9f3.png');
    padding-top: 120px !important;
    form{
        width: 100%;
        max-width: 790px;
        height: 68px !important;

        .form-control{
            border-radius: 6px;
            border: 1px solid $text-light-primary;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
            color: $text-dark-tertiary;
    
            &::placeholder{
                color: $text-dark-tertiary;
            }
        }

        button{ 
            border: 1px solid $stroke-dark;
            &:hover,
            &:focus {
                background-color: $my-gradient !important;
                background: $my-gradient !important;
                border-color:$my-gradient !important;
            }
            padding: 0px 30px !important;
        }
    }

    .result{
        height: 500px;
        background-color: $background-dark-secondary;
        max-width: 775px;
        display: none;
        overflow: auto;
    }
}

#platformDataUpdate{
    padding: 120px 0px 60px 0px;
    background-image:url('https://www.analyticsvidhya.com/static/media/hero-image-bb.a132de9e5cd9abaef9f3.png');
    background-attachment: fixed;
}

#createTournament,#createStage{
    input[type=datetime-local]{
        &::-webkit-calendar-picker-indicator {
            filter: invert(0);
        }
    }
}

#datahackAdminPortal {
    .card-content {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
}

.btn-delete{
    top: -15px;
    right: -15px;
}