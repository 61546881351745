.view-stats-modal,.compare-stats-modal {
    table {
        border-color: $text-light-primary;
        td {
            background-color: $background-dark-primary !important;
            padding: 22px 24px;
        }
        th{
            background-color: $background-dark-tertiary !important;
            padding: 22px 24px;
        }
    }
}

.view-stats {
    table {

        td {
            border: 1px solid $text-light-primary;

        }
    }
}

#leaderboard{
    #hackathonDetailContent table td{
        &.collapse,&.collapsing{
            padding: 0px !important;
        }
    }
}