#aboutUs {
    .center {
        @media (max-width:768px) {
            text-align: center;
        }
    }
}

#heroAboutUs {
    h2 {
        @media (max-width: 599px) {
            font-size: $font-size-large-x;
        }
    }
    @media (max-width: 768px) {
        padding: 120px 0 0 0 !important;
    }
}

#ourStory {
    .event-date::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 94px; 
        width: calc(100% - 94px);
        height: 1px;
        background-color: $stroke-light-secondary;
        display: block;
        transform: translateY(-50%);

        @media (max-width:768px) {
            display: none;
        }
    }
    
    .timeline {
        @media (max-width:768px) {
            border-left: 1px solid $stroke-dark;
        }

        h5{
            width: fit-content !important;
        }
    }

    .img-for-mobile {
        @media (max-width: 768px) {
            display: block !important;
        }
    }
    .img-for-desktop {
        @media (max-width:768px) {
            display: none;
        }
    }
}

#ourLeadershipTeam {
    .card {
        .zoom-card {
            overflow: hidden;
            position: relative;
            background-color: $white-10;

            &:hover,
            &:focus {
                // background-color: $my-gradient !important;
                // background: $my-gradient !important;
                // -webkit-transition: background-color 1000ms ease-in-out;
                // -ms-transition: background-color 1000ms ease-in-out;
                // transition: background-color 1000ms ease-in-out;

                img {
                    -webkit-transition: transform 0.7s ease-in-out;
                    -ms-transition: transform 0.7s ease-in-out;
                    transition: transform 0.7s ease-in-out;

                    -webkit-transform: scale(1.1);
                    -ms-transform: scale(1.1);
                    transform: scale(1.1); 
                }
            }

            .btn {
                bottom: 1%;
                font-weight: $font-weight-medium-x;

                &:hover,
                &:focus {
                    border: 1px solid $blue;
                }
            }

            &:hover,
            &:focus {
                .btn {
                    display: block !important;
                }
            }
        }

        .btn-for-mobile {
            border: 1px solid $stroke-dark;
            background-color: $cta-dark-primary;
            font-weight: 600;

            @media (max-width:768px) {
                display: flex !important;
                width: fit-content !important;
                align-items: center;
            }
        }

        h5 {
            font-weight: $font-weight-medium-x;
        }
    }
}

#AvInNumbers {
    background-color: #c8d8f0;

    ul {

        li {
            flex: 1;
            border-right: 1px solid $stroke-light-secondary; 

            &:last-child {
                border-right: none; 
            }
        }

        @media (min-width:600) and (max-width:1199px) {
            display: grid !important;
            grid-template-columns: repeat(3, 1fr);
            gap: 2em;

            li {
                &:nth-child(3n) {
                    border-right: none;
                }
            }
        }

        @media (max-width:768px) {
            display: grid !important;
            grid-template-columns: repeat(2, 1fr);
            gap: 2rem 0;
            li {
                &:nth-child(2n) {
                    border-right: none;
                }
            }
        }
    }
}

#howItHelps {
    .video-column {
        @media (max-width:1199px) {
            padding-left: 0px !important;
            padding-right: 0px !important;
        }
        .video-thumbnail {
            background-color: $white-10;
        }
    }
    h5 {
        @media (max-width:768px) {
            font-size: 28px !important;
            line-height: 42px;
        }
    }
}

#awardsAndRecognition {
    background-color: $white-10;
}

#lifeAtAv {
    .btn {
        font-weight: $font-weight-medium-x;
        background-color: $my-gradient !important;
        background: $my-gradient !important;
        border-color:$my-gradient !important;
    }
    h5 {
        bottom: 30px;
        left: 16px;

        @media (max-width:599px) {
            font-size: $font-size-small-x;
        }
    }

    .image-rows {
        @media (max-width:599px) {
            padding-left: 0.5rem !important;
            padding-right: 0.5rem !important;
        }
    }

    .image-gradient{
        position: relative;
        &::before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(2deg, #151316 2.45%, rgba(21, 19, 22, 0.00) 77.56%);        }
    }
}

.gradient-cirle-diagram{
    .mobile-circle{
        ul{
            &::before{
                background: linear-gradient(180deg, #D22D1E -0.08%, #963AB1 52.26%, #20469B 99.82%) !important;
            }

            li{
                margin-bottom: 40px !important;
                position: relative;

                &::before{
                    content: url('https://d2cd20fxv8fgim.cloudfront.net/homepage/images/up.svg');
                    position: absolute;
                    top: calc(50% + 40px);
                    left: -46px;
                }

                &:last-child{
                    &::before{
                        display: none !important;
                    }
                }
            }
        }
    }
}