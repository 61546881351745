header {
    .container {
        @media (max-width:991px) {
            justify-content:flex-start;
        }

    }

    .btn-outline-primary {
        @media (max-width:991px) {
            display: none;
        }
    }

    .navbar-toggler {
        @media (max-width:991px) {
            padding: 0px !important;
            border: 0px !important;
            margin-left: 10px !important;
            box-shadow: none !important;
        }
    }

    .navbar-brand  {
        img {
            @media (max-width:991px) {
                width: 120px !important;
            }
        }
        &.me-5 {
            @media (max-width:991px) {
                margin-right: 1rem !important;
            }
        }
       
    }

    position: relative;
    .header-right {
        @media (max-width:991px) {
            margin-left: auto;
        }
        ul {
            li {
                list-style: none !important;
                margin-bottom: 10px;
            }
        }
        .dropdown-toggle{
            &::after {
                border-top: 0.1em solid transparent;
                border-right: 0.1em solid #080808;
                border-bottom: 0.1em solid #000;
                border-left: 0.1em solid transparent;
                width: 8px;
                height: 8px;
                transform: rotate(45deg);
                display: inline-block;
                margin-left: 0.7em;
                transition: all .2s;
                @media (max-width:991px) {
                    display: none;
                }
            }

            &.show {
                &::after {
                    transform: rotate(-135deg);
                }
            }
        }
    }

    .btn.btn-dark.btn-dark-primary.btn-no-hover {
        &:hover, &:focus {
            background-color: transparent !important;
            background: transparent !important;
            color: $white !important;
            
        }

    }
    .btn-no-hover{
        @media (max-width:799px) {
            display: none !important;
        }
    }

    .av-mega-menu {
        .menu-item {
            &.hover {
                .mega-menu {
                    visibility: visible;
                    height: auto;
                    opacity: 1;
                    transform: rotateX(0deg);
                    -o-transform: rotateX(0deg);
                    -moz-transform: rotateX(0deg);
                    -webkit-transform: rotateX(0deg);
                }

                .av-down-arrow {
                    &::after {
                        transform: rotate(223deg);
                        position: relative;
                        top: 5px;
                    }
                }

            }
        }
    
    }

    .mega-menu {
        visibility: hidden;
        opacity: 0;
        height: 0px;
        width: 100%;
        left: 0px;
        position: absolute;
        top: 92px;
        color: $background-dark-primary;
        z-index: 1000;;
        margin: 0px;
        text-align: left;
        padding: 14px 5px;
        font-size: 15px;
        border: solid 1px #eeeeee;
        background-color: #fff;
        -o-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
        -moz-transform-origin: 0% 0%;
        -webkit-transform-origin: 0% 0%;
        -o-transition: -o-transform 0.3s, opacity 0.3s;
        -ms-transition: -ms-transform 0.3s, opacity 0.3s;
        -moz-transition: -moz-transform 0.3s, opacity 0.3s;
        -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
        transform-style: preserve-3d;
        -o-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        -webkit-transform-style: preserve-3d;
        transform: rotateX(-75deg);
        -o-transform: rotateX(-75deg);
        -moz-transform: rotateX(-75deg);
        -webkit-transform: rotateX(-75deg);
        transition: transform 0.3s, opacity 0.3s;
        @media (max-width:991px) {
            position: relative;
            top: 0px;
            border: 0px;
            box-shadow: none !important;
        }
        .menu-mega-left-nav  {
            position: relative;
            height: 100%;
            left: 0;
            top: 0;
            background: $white;
            list-style: none;
            transition: all 0.3s !important;
     
            li {
                padding: 16px 40px;
                width: 20%;
                border-right: solid 1px $troke-light-primary;
                @media (max-width:991px) { 
                    width: 100%;
                    padding: 0px;
                    border-right: none;
                    padding-bottom: 5px;
                    padding-top:15px;
                    border-bottom: solid 1px $troke-light-primary;
                }
                a {
                    text-decoration: none;
                    color: $text-dark-tertiary;
                    font-size: $font-size-x-medium;
                    position: relative;
                    @media (max-width:991px) {
                        font-size:  $font-size-regular;
                    }
                }

                &:hover,&.active {
                    background: $white-10;
                    @media (max-width:991px) { 
                        background: transparent !important;
                    }
                    a {
                        color: $blue;

                        path {
                            stroke: $blue !important;
                        }
                    }
                }

                &:hover,&.active {
                    .menu-mega-right-nav{
                        opacity: 1;
                        visibility: visible;
                        transform: rotateX(0deg);
                        -o-transform: rotateX(0deg);
                        -moz-transform: rotateX(0deg);
                        -webkit-transform: rotateX(0deg);
                        @media (max-width:991px) { 
                            height: auto;
                        }
                        
                    }
                    
                }

                &.active {
                    background: $white-10;
                    @media (max-width:991px) { 
                        background: transparent;
                    }
                    .menu-mega-right-nav{
                        opacity: 1;
                        visibility: visible;
                        transform: rotateX(0deg);
                        -o-transform: rotateX(0deg);
                        -moz-transform: rotateX(0deg);
                        -webkit-transform: rotateX(0deg);
                    }
                }
            }
        }

        .menu-mega-right-nav {
            transition: visible 0.3s !important;
            width: 80%;
            position: absolute;
            padding: 24px 40px;
            opacity: 0;
            visibility: hidden;
            left: 20%;
            top: 0;
            -o-transform-origin: 0% 0%;
            -ms-transform-origin: 0% 0%;
            -moz-transform-origin: 0% 0%;
            -webkit-transform-origin: 0% 0%;
            -o-transition: -o-transform 0.3s, opacity 0.3s;
            -ms-transition: -ms-transform 0.3s, opacity 0.3s;
            -moz-transition: -moz-transform 0.3s, opacity 0.3s;
            -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
            transform-style: preserve-3d;
            -o-transform-style: preserve-3d;
            -moz-transform-style: preserve-3d;
            -webkit-transform-style: preserve-3d;
            transform: rotateX(-75deg);
            -o-transform: rotateX(-75deg);
            -moz-transform: rotateX(-75deg);
            -webkit-transform: rotateX(-75deg);
            height: 100%;
            @media (max-width:991px) { 
                width: 100%;
                left: 0;
                padding: 0px;
                position: relative;
                border: 0px !important;
                margin-top: 15px;
                height: 0;
            }

            a {
                color: $text-light-primary !important;
                font-size: $font-size-x-medium;
                margin-bottom: 20px;
                display: block;

                small {
                    color: $text-dark-tertiary;
                    margin-top: 10px;
                    font-size: $font-size-small !important;
                }

                svg {
                    stroke: $text-light-primary !important;
                    path {
                        stroke: $text-dark-tertiary !important;
                    }
                }
            }

            .row {
                .col-4 {
                    @media (max-width:991px) {
                        width: 100%;
                    }
                }
            }
        }
        &.active {
            visibility: visible;
            height: auto;
            opacity: 1;
            transform: rotateX(0deg);
            -o-transform: rotateX(0deg);
            -moz-transform: rotateX(0deg);
            -webkit-transform: rotateX(0deg);
        }

    }

    #bellHeaderBtn,
    #writeHeaderBtn {
        @media (max-width:991px) {
            display: none;
        }
    }

    .icon-btn {
        @media (max-width:991px) {
            padding: 0px !important;
            margin-right: 15px;
        }
    }

    .btn-close {
        display: none;
        @media (max-width:991px) {
            display: block;
        }
    }
   
    &.dark-mode{
        .mega-menu {
            border: solid 1px $background-dark-secondary !important ;
            .menu-mega-left-nav  {
                background: $background-dark-secondary !important;
         
                li {
                    border-right: solid 1px $background-dark-secondary;
                    a {
                        color: $text-dark-tertiary;
                    }
    
                    &:hover,&.active {
                        background: $background-dark-tertiary;
                        a {
                            color: $white;
                            margin-bottom: 45px;
                            @media (max-width:991px) {
                                font-size:  $font-size-regular;
                                margin-bottom: 20px;
                            }
                            path {
                                stroke: $white !important;
                            }
                        }
                    }
    
                    &.active {
                        background: $background-dark-tertiary;
                    }
                }
            }
    
            .menu-mega-right-nav {
                a {
                    color: $white !important;
                }
            }
        }

        .offcanvas {
            background-color: $background-dark-secondary;
        }
    }



    @keyframes rotate {
        0%{
            transform: translate(-50%, -50%) rotate(0turn);
        }
        100% {
            transform: translate(-50%, -50%) rotate(1turn);
        }
    }

    .rainbow {
        position: relative;
        z-index: 0;
        border-radius: 10px;
        overflow: hidden;
        padding: 1px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: sans-serif;
        font-weight: bold;
        background: #1E1C1F;

        .btn.btn-dark.btn-dark-primary.btn-no-hover{
            border: 0px !important;
            background: #1E1C1F !important;
            &:hover{
                background: #1E1C1F !important;
            }
        }
        
        /* Spinning rainbow pseudo-element */
        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 120%;
            aspect-ratio: 1;
            background-image: linear-gradient(to right, #D22D1E, #963AB1,#20469B, $stroke-dark 50%);
            animation: rotate 4s linear infinite;
            z-index: -1;
            border-radius: 50%;
        }

        &::after {
            content: '';
            position: absolute;
            z-index: -1;
            left: 6px;
            top: 6px;
            width: calc(100% - 12px);
            height: calc(100% - 12px);
            background: #1E1C1F;
            border-radius: 5px;
        }
    }

}

.btn span.av-down-arrow {
    position: relative;
    border: none !important;
      &::after {
        border-top: 0.1em solid transparent;
        border-right: 0.1em solid $white;
        border-bottom: 0.1em solid $white;
        border-left: 0.1em solid transparent;
        width: 8px;
        height: 8px;
        transform: rotate(45deg);
        display: inline-block;
        margin-left: 0.7em;
        transition: all .2s;
        vertical-align: 0.255em;
        content: "";
    }
  
    &.show {
      &::after {
        transform: rotate(-135deg);
      }
    }
}

