@import 'bb-header';
@import 'sprite-images';

#firstFold {
    padding-top: 100px;

    h1{ 
        @media (max-width:768px) {
            text-align: left;
        }
    }
    span{
        -webkit-text-fill-color:initial;
        
        @media (max-width:321px) {
            word-break: break-all;
        }
    }

    .text-gradient{
        background: var(--brand-colors-primary-gradient, linear-gradient(90deg, #D22D1E -0.08%, #963AB1 52.26%, #20469B 99.82%)) !important;
        -webkit-background-clip: text !important;

        @media (max-width:599px) {
            font-size: 36px !important;
        }
    }

    ul{

        @media (max-width:768px) {
            text-align: left;
            padding-left: 0px !important;
        }
        li {
            list-style: none;
            margin-bottom: 16px;
            font-size: $font-size-regular;
            i {
                width: 8px;
                height: 8px;
                background: $white;
                @include border-radius(30px);
                display: inline-block;
                margin-right: 8px;
            }
        }
    }

    .enroll-number {
        background: $background-dark-secondary;
        border: 1px solid $stroke-dark;

        @media (max-width:599px) {
            width: 100% !important;
        }

        .form-control {
            background: $background-dark-tertiary;
            border-color: $text-light-primary;
            color: $white;
            padding-top: 12px !important;
            padding-bottom: 12px !important;

            &[type=tel]{
                padding-left: 85px !important;
            }
        }

        ::-ms-input-placeholder { /* Edge 12-18 */
            color: $text-dark-tertiary;
          }
          
          ::placeholder {
            color: $text-dark-tertiary;
          }
        .form-check-input {
            background-color: $background-dark-tertiary;
            border-color: $stroke-dark;
            width: 1.3rem;
            height: 1.3rem;
            margin-top: 2px !important;
            &:checked {
                background-color: $background-dark-tertiary;
                border-color: $stroke-dark;
            }
        }
        label {
            margin-left: 8px;
            font-size: $font-size-regular;
            opacity: 0.5 !important;
        }
        .iti__selected-flag {
            padding: 0 14px 0 15px;
            border-right: solid 1px $stroke-dark;
        }

        .iti__arrow {
            height: 8px;
            width: 8px;
            border-style: solid;
            border-color: $text-dark-tertiary !important;
            border-width: 0px 2px 2px 0px !important;
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
            margin-left: 14px;
        }

        .btn-gradient{
            padding: 12px 18px !important;
        }

        .btn-desktop{
            @media (max-width:1024px) {
                display: none !important;
            }
        }
        .btn-mobile{
            @media (min-width:1025px) {
                display: none !important;
            }
        }
    }

    .offer-block {
        bottom: 50px;
        right: 100px;
        display: flex;
        flex-direction: column;

        @media (max-width:1025px) {
            position: relative !important;
            width: fit-content !important;
            margin: 24px auto;
            right: 0px;
            bottom: 0px;
        }

        a {
            background: $orange;
            position: relative;
            // width: fit-content;
            display: flex;
            align-items: center;
            padding: 8px 16px;
            &::before{
                content: "";
                border-bottom: 20px solid $orange;
                border-top: 20px solid $orange;
                border-left: 20px solid transparent;
                position: absolute;
                top: 0px;
                z-index: 2;
                left: -20px;
                
            }
           
        }
        .timmer-box{
            width: 30px !important;
            margin-left: 10px;
        }

        .timmer-strip{
            @media (max-width:1025px) {
                margin-left: 0px !important;
            }
        }
    }
}


#whyChoose {
    position: relative;
    background-color: $background-dark-primary;
    .shpe-left {
        position: absolute;
        top: 0;
        left: 0;
        @media (max-width:599px) {
            display: none;
        }
    }
    h3{
        margin-bottom: 20px !important;
    }
    div.key-points{
        ul{
            margin-bottom: 70px !important;

            @media (max-width:599px) {
                padding-left: 15px;
            }

            li{
                margin-bottom: 20px !important;

            }
        }

        &:last-child{
            ul{
                margin-bottom: 0px !important;
            }
        }
    }

    .text-bg-dark {
        background-color: $background-dark-secondary !important;

        @media (max-width:599px) {
            background-color:transparent !important;
            padding: 0px 5px !important;
        }
    }

    .icon-item {
        .icon-box {
            width: 48px;
            height: 48px;
            padding: 12px;
            background: $background-dark-tertiary;
        }
    }

    .btn-dark{
        @media (max-width:599px) {
            width: 100%;
        }
    }
}

#roadMap {
    img{
        @media (max-width:599px) {
            display: none;
        }
    }
    .roadmap-mobile{
        list-style: none;
        padding-left: 0px;
        display: none;

        @media (max-width:599px) {
            display: block;
        }

        li{
            padding-bottom: 40px;
            position: relative;
            color: white !important;


            &::before{
                content: "";
                position: absolute;
                width: 10px;
                height: 100%;
                background-color: $background-dark-tertiary;
                top:calc(34px / 2);
                left:11px ;
                z-index: 0;
                border: 1px solid $stroke-dark;
            }

            &::after{
                content: '';
                position: absolute;
                width: 1px;
                height: 20px;
                background-color: $white;
                left:15px ;
                top: 56%;
            }

            &:last-child{
                padding-bottom:32px ;
                &::after,&::before{
                    display: none;
                }
            }
        }
        
        .roadmap-number{
            margin-right: 5px;
            width: 34px;
            height: 34px;
            display: inline-block;
            background: linear-gradient($background-dark-tertiary, $background-dark-tertiary) padding-box,
              $my-gradient border-box;
            border: 4px solid transparent;
            z-index: 1;
        }
    }

    .btn-dark{
        @media (max-width:599px) {
            width: 100%;
        }
    }
}

#chooseLearn {
    .heading-with-text h2,p{
        @media (max-width:599px) {
            text-align: left;
        }
    }
    .right-shap {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
    }

    .play-btn, .btn {
        z-index: 9;
    }

    .accordion {
        height: 457px;
        overflow-y: auto;
        .accordion-item {
            background-color: transparent !important;
            background: transparent !important;
        }

        .accordion-body {
            height: 190px;
            overflow-y: auto;
        }
    
        .accordion-button {
            background-color: $background-dark-tertiary !important;
            padding: 16px 24px;
            &:focus {
                box-shadow: none !important;
            }
        }
        .accordion-button:not(.collapsed) {
            box-shadow: none !important;
        }

        h4 {
            line-height: 24px;
        }

        ol {
            li {
                list-style: decimal !important;
                color: $text-dark-tertiary;
                margin-bottom: 16px;
                font-size: $font-size-regular;
                font-weight: 300;
                transition: all .5s;
                &:hover{
                    color: $white;
                }
            }
        }
    }
    .accordion-button::after {
        filter: invert(50%);
    }
}

#tool{
    .tools-image-wrapper{
        width: calc(100% / 7 - 1.5rem);

        @media (max-width:1200px) {
            width: calc(100% / 6 - 1rem);
        }
        @media (max-width:599px) {
            width: calc(100% / 3 - 1rem);
        }
    }

    .swiper-slide{
        width: 160px;
    }
    .swiper-wrapper{
        transition-timing-function: linear;

        .bb-sprite2{
            margin: 0px auto;
        }
    }
}

#mentors {
    .swiper {
        .swiper-slide {
            height: auto !important;
        }
      }
    .card-img {
        position: relative;
        transition: all .5s ease-in;
        overflow: hidden;
        .card-overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            visibility: none;
            transition: .5s ease;
            background-color: $background-dark-secondary;
            padding: 10px;
            display: flex;
            align-items: center;
        }

        &:hover {
            .card-overlay {
                opacity: 1;
            }
        }
    }

    .btn-dark{
        @media (max-width:599px) {
            width: 100%;
        }
    }
}

#placementAssistance {
    ul {
        justify-content: center;
        display: flex;
        flex-direction: column;
        height: 100%;

        @media (max-width:599px) {
            padding-left: 0px;
        }
        li {
            list-style: none;
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .icon-bog {
                width: 50px;
                height: 50px;
                margin-right: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            p {
                overflow: hidden;
                margin: 0;
            }
        }
    }
}

#avPlacementAssistance {
    padding-bottom: 120px; 
    @media (max-width:991px) {
        padding-bottom: 79px; 
    }  
}

#learnersTestimonial {

    h2, h3 {
        line-height: 36px;
    }
    .swiper {
        .swiper-slide {
            height: auto !important;
        }
    }
}

#moneyGuarantee {
    .form-check-input {
        width: 48px !important;
        height: 24px !important;

        &.custom-control-input{
            background: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e'),$my-gradient  !important;
            background-repeat: no-repeat !important;       
            background-position: left center !important;

            &:checked{
                background-position: right center !important;
            }
        }
    }
    
    .toggle-card{
        display: none;

        &.active{
            display: block !important;
        }
    }
}

.box-annimation {
    width: fit-content;
    padding: 4px;
    --border-size: 4px;
    --border-angle: 0turn;
    background-image: conic-gradient(from var(--border-angle), #fff, #fff 50%, #fff), conic-gradient(from var(--border-angle), white 0%, #dede, rgb(127, 126, 126));
    background-size: calc(100% - (var(--border-size) * 2)) calc(100% - (var(--border-size) * 2)), cover;
    background-position: center center;
    background-repeat: no-repeat;
    animation: bg-spin 100s linear infinite;
    border-radius: 8px;
    border-width: 10px;
    overflow: hidden;
    &:hover {
        animation-play-state: paused;
    }
}

@keyframes bg-spin {
to {
    --border-angle: 1turn;
}
}

@property --border-angle {
syntax: "<angle>";
inherits: true;
initial-value: 0turn;
}
  

#offerSection {
h3 {
    background: var(--brand-colors-primary-gradient, linear-gradient(90deg, #D22D1E -7.92%, #963AB1 19.26%, #20469B 51.82%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.timmer-card {
    ul {
        border-radius: 8px;
        border: 1px solid var(--brand-colors-primary-gradient, #D22D1E);
        li {

        }
    }
    
}

.offer-cta{
    background: #F9C823;
    position: relative;
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 8px 16px;

    &::before{
        content: "";
        border-bottom: 20px solid $orange;
        border-top: 20px solid $orange;
        border-right: 20px solid transparent;
        position: absolute;
        top: 0px;
        z-index: 2;
        right: -20px;
        
    }
}
}

.lead-modal{
    .modal-dialog{
        max-width: 648px;

        .modal-content{
            padding: 24px 16px !important;


            .form-control{
                border-radius: 6px;
                border: 1px solid $text-light-primary;
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
                color: $text-dark-tertiary;

                &::placeholder{
                    color: $text-dark-tertiary;
                }
            
            }

            .experience-tag{
                .form-check-input{
                    &:checked{
                        &+.form-check-label{
                            background:linear-gradient(279deg, #D22D1E -0.08%, #963AB1 52.26%, #20469B 99.82%) !important;
                            background-image:linear-gradient(279deg, #D22D1E -0.08%, #963AB1 52.26%, #20469B 99.82%) !important;
                            color: $white !important;
                        }
                    }
                }
            }

            input[type=checkbox]{
                width: 24px;
                height: 24px;
                border: 1px solid $stroke-dark;
                background: $background-dark-tertiary;
                margin-top: 2px !important;

                &:checked{
                    background-image: url(https://av-eks-newhomepage.s3.ap-south-1.amazonaws.com/images/icon/check.svg);
                    background-repeat: no-repeat;
                    background-position: center center;
                }
            }
        }
    }
}

#faq {
    .accordion{
        color: $white !important;
        --bs-accordion-bg: $background-dark-primary !important;
        .accordion-header {
           button {
            color: $white !important;
            &::after {
                filter: invert(5) !important;
            }
           }
        }
        .accordion-item {
            color: $white !important;
            background-color: $background-dark-primary !important;
            @include border-radius(10px);
        }

        .accordion-item .accordion-button {
            @include border-radius(10px);
        }

        .accordion-body {

        }
    }

    .accordion-button:not(.collapsed) {
        box-shadow: none !important;
        border: 0px !important;
    }
    .accordion-button {
        background-color: $background-dark-primary !important;

        &::after{
            background-image: var(--bs-accordion-btn-icon) !important;
        }

        &:focus{
            box-shadow: none !important;
        }
    }
}

#contact {
    .card{
        border-color: $stroke-dark !important;
        .experience-tag{
            .form-check-input{
                &:checked{
                    &+.form-check-label{
                        background:linear-gradient(279deg, #D22D1E -0.08%, #963AB1 52.26%, #20469B 99.82%) !important;
                        background-image:linear-gradient(279deg, #D22D1E -0.08%, #963AB1 52.26%, #20469B 99.82%) !important;
                        color: $white !important;
                    }
                }
            }
        }

        .form-control{
            border-radius: 6px;
            border: 1px solid $text-light-primary;
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
            color: $text-dark-tertiary;

            &::placeholder{
                color: $text-dark-tertiary;
            }
        
        }

        input[type=checkbox]{
            width: 24px;
            height: 24px;
            border: 1px solid $stroke-dark;
            background: $background-dark-tertiary;
            margin-top: 2px !important;

            &:checked{
                background-image: url(https://av-eks-newhomepage.s3.ap-south-1.amazonaws.com/images/icon/check.svg);
                background-repeat: no-repeat;
                background-position: center center;
            }
        }
    }
    
    ul{
        @media (max-width:599px) {
            padding-left: 0px;
        }
    }
}

#alumniLogo{
    .swiper-wrapper{
        transition-timing-function: linear;
    }
}

#reinforceLearning,#personalised{
    .swiper-slide {
        height: auto !important;
    }

    .btn-dark{
        @media (max-width:599px) {
            width: 100%;
        }
    }

    .card{
        .card-overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 100%;
            width: 100%;
            opacity: 0;
            visibility: none;
            transition: .5s ease;
            background-color: $background-dark-secondary;
            padding: 10px;
            display: flex;
            flex-direction: column;
            z-index: 2;

            ul{
                padding-left: 1rem !important;
            }
        }
    
        &:hover {
            .card-overlay {
                opacity: 1;
            }
        }
    }
}

#certification{
    .heading-with-text h2,p{
        @media (max-width:599px) {
            text-align: center;
        }
    }

    p{
        line-height: 36px !important;
    }

    .btn-desktop{
        @media (max-width:1024px) {
            display: none;
        }
    }

    .btn-mobile{
        display: none;
        @media (max-width:1025px) {
            display: block;
        }
    }

    .certificate-image{
        background-image: url('https://d2cd20fxv8fgim.cloudfront.net/blackbelt/certificate%20bg.svg');
        background-repeat: no-repeat;
        background-position: bottom 30px right 10px;

        @media (max-width:768px) {
            background-position: bottom 30px right 30px;
        }
    }
}

#moneyBackGaurantee{
    h2,p{
        @media (max-width:599px) {
            text-align: center;
        }
    };
}

.video-modal{
    .modal-dialog{
        max-width: 900px;
    }
}

