#leaderboard{
    #hackathonDetailContent{
        .card-top{
            background-color: $background-dark-tertiary;
            height: 80px;

            &.bg-gradient{
                background: $my-gradient !important;
                background-color: $my-gradient !important;
            }
        }

        .avtar-image{
            margin-top: -35px;
            margin-left: 10px;
        }

        .badge-image{
            margin-top: -50px;
        }

        table{
            td{
                padding: 24px !important;
            }
            tr{
                &:nth-child(2n){
                    background-color: $background-dark-primary;
                }

                &.active{
                    border-top: 1px solid transparent;
                    border-bottom: 1px solid transparent;
                    .leaderboard-avtar-wrapper{
                        border: 1px solid transparent;
                        background: linear-gradient($background-dark-tertiary, $background-dark-tertiary) padding-box,
                        $my-gradient border-box;
                        z-index: 1;
                    }
                    background: linear-gradient($background-dark-tertiary, $background-dark-tertiary) padding-box,
                    $my-gradient border-box;
                }

                .btn-active{
                    display: none;
                }

                &:has(button[aria-expanded=true]){
                    background-color: $background-dark-tertiary;

                    .btn-active{
                        display: inline-block;
                    }
                }

            }

            svg{
                background-color: transparent !important;

                circle{
                    fill: transparent !important;
                }
            }
            
            
        }
    }

    .form-check-input {
        width: 48px !important;
        height: 24px !important;

        &.custom-control-input{
            background: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e'),$background-dark-secondary !important;
            background-repeat: no-repeat !important;       
            background-position: left center !important;

            &:checked{
                background: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e'),$my-gradient  !important;
                background-position: right center !important;
                background-repeat: no-repeat !important;   
            }
        }
    }

    .leaderboard-avtar-wrapper{
        svg{
            top: 5px;
            left: -46px;
        }
    }
}