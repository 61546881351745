#agenticai{
    #firstFold{
        .text-gradient{
            background: var(--brand-colors-primary-gradient, linear-gradient(90deg, #D22D1E -0.08%, #963AB1 52.26%, #20469B 99.82%)) !important;
            -webkit-background-clip: text !important;
            -webkit-text-fill-color:transparent;
            
        }
    
        h2{
            width: fit-content;
        }
    
        .enroll-number{
            margin-top: 60px !important;
        }

        ul{

            @media (max-width:768px) {
                text-align: left !important;
                padding-left: 0px !important;
            }
        }
    }
    
    .right-shap {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
    }

    .btn-slider-group{
        width: fit-content;
    }



    #certification{
        margin-top: 120px;

        .certificate-border{
            border-color: rgba(255,255,255, 0.50) !important;
        }
    }

    #chooseLearn .accordion{
        height: 400px;
    }

    .company-image-wrapper {
        @media (max-width:1199px) {
            padding-top: 1rem;
        }
    }

    @keyframes rotate {
        0%{
            transform: translate(-50%, -50%) rotate(0turn);
        }
        100% {
            transform: translate(-50%, -50%) rotate(1turn);
        }
    }
}


.common-card-style {
    &.agentic-card {
        .card-img {
            aspect-ratio:auto !important;
        }
        .card-footer{
            min-height: 70px !important;
        }
    }
} 


.rainbow {
    position: relative;
    z-index: 0;
    border-radius: 10px;
    overflow: hidden;
    padding: 2px;
    justify-content: center;
    align-items: center;
    background: #1E1C1F;

    .btn.btn-dark.btn-dark-primary.btn-no-hover{
        border: 0px !important;
        background: #1E1C1F !important;
        &:hover{
            background: #1E1C1F !important;
        }
    }
    
    /* Spinning rainbow pseudo-element */
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 120%;
        aspect-ratio: 1;
        background-image: linear-gradient(to right, #D22D1E, #963AB1,#20469B, $stroke-dark 50%);
        animation: rotate 4s linear infinite;
        z-index: -1;
        border-radius: 50%;
    }

    &::after {
        content: '';
        position: absolute;
        z-index: -1;
        left: 2px;
        top: 2px;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        background: #1E1C1F;
        border-radius: 5px;
    }

    @media (max-width: 768px) {
        background: none; 

        &::before, &::after {
            display: none;
        }
    }
}
