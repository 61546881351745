.av-tab-design{
    background-color: $background-dark-tertiary;
    border: 1px solid $stroke-dark;
    .nav-item{
        padding: 0px 20px;
        .nav-link{
            background-color: transparent;
            border: 0px;
            color: $text-dark-secondary;
            padding: 0px;

            &.active{
                color: $white;
                border-bottom: 1px solid $white;
            }
        }
    }
}

#datahourDetailContent{
    #discuss{
        textarea{
            background-color: transparent;
            border: 0px;
            box-shadow: none;
            color: white;
            resize: none;

            &::placeholder{
                color: $text-dark-tertiary;
            }
        }
    }
}

.not-login-blur{
    height: 100%;
    min-height: 100%;
    width: 100%;
    .blur{
        backdrop-filter: blur(4px); 
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 4;
    }
    p{
        z-index: 4;
    }
}

.reply-card,.comment-card{
    border-bottom: 1px solid $stroke-dark;

    &:last-child{
        border: 0px;
    }
}

.comment-section{
    .card{
        max-height: 700px;
    }
    p{
        white-space: pre-line;
    }
    .dropdown-toggle{
        &::after{
            display: none !important;
        }
    }
}



.banner-image-wrapper{
    &:hover{
      .overlay{
        display: flex !important;
      }

      h3{
        text-decoration: underline !important;
      }
    }
}

#transcript {
    .icon-wrapper {
        width: 2.5rem;
        height: 2.5rem;
    }
    .single-note-wrapper{
        border-bottom: 1px solid $stroke-dark;
    }
}

.img-ratio {
    aspect-ratio: 2/1;
}

.text-gradient-events {
    background: var(--brand-Colors-primary-gradient, linear-gradient(90deg, #D22D1E -0.08%, #963AB1 30.26%, #20469B 70.82%, #20469B 99.82%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}