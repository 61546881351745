#b2b{
    #b2bHeroFold{
        background-position: top right;
        background-repeat: no-repeat;
        background-size: contain;
        height: 100vh;
        @media (max-width:1024px) {
          padding-top: 7rem;
          height: auto !important;
        }

        @media (max-width:799px) {
            background: $background-dark-secondary !important;
            padding-top: 120px !important;

            .container{
                min-height: auto;
            }
            
            h1{
                font-size: 36px !important;
            }
        }

        .text-gradient {
          background: var(--Brand-Colors-primary-gradient, linear-gradient(90deg, #D22D1E -0.08%, #963AB1 52.26%, #20469B 99.82%, #20469B 99.82%));
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
    }

    #experience{
        .heading-with-text{
            h2,p{
                color: $text-light-primary !important;
            }
        }

        &.event-tabs{
            .nav{
              --height:33.33%;
              &-4{
                --height:25%;
              } 
              .nav-link{
                a{
                    display: none;
                }

                &.active{
                  background-color: transparent !important;
                  color: $text-light-primary !important;
                  a{
                    display: block;
                  }
                }
              }

              &::before{
                background: $blue !important;
              }

              &::after{
                background: $troke-light-primary !important;
              }
            }
            #experienceTabs 
            {
                
              a[data-bs-toggle=collapse]{
                p,a{  
                    display: none !important;
                }

                ::after{
                  background: #DAE0E6;
                }

                &[aria-expanded=true]{
                  background-color: transparent !important;

                  h3,p{  
                      color: $text-light-primary !important;
                  }

                    p,a{  
                        display: block !important;
                    }

                  ::after{
                        background: $blue;
                        width: 4px;
                        left: -1px;                  
                    }
                }
              }
            }
        }
    }

    #b2bPartnerWithUs{
        background-color: $white-10;
    }
}

#avAdvantage{
  position: relative;
  background: url("https://imgcdn.analyticsvidhya.com/b2b/snake_timelines.png?format=webp");
  background-repeat: no-repeat;
  background-position: 36% 55%;
  background-size: 60%;

  @media (max-width:989px) {
    background: none !important;
  }
}

#aiEdge{
  background: url("https://imgcdn.analyticsvidhya.com/b2b/ai_edge.png?format=webp");
  background-repeat: no-repeat;
  background-size: cover;
}

.enterprise-header{
  .nav-link{
    &:hover{
      color: $blue !important;
      text-decoration: underline !important;
    }
  }
}

#b2bPartnerWithUs{
  img {
    transition: all ease-in-out 0.3s;
  }
  li:hover{
    img {
      transform: scale(1.2);
    }
    p {
      text-decoration: underline !important;
    }
  }
  .hover-transition:hover{
    img {
        transform: scale(1.2);
      }
    h2 {
      text-decoration: underline !important;
    }
  }
}


.py-120 {
  padding: 120px 0px;
  @media (max-width:991px) {
    padding: 90px 0px;
  }
}

.py-60 {
  padding: 60px 0px;
  @media (max-width:991px) {
    padding: 30px 0px;
  }
}

.pb-60 {
  padding-bottom: 60px;
  @media (max-width:991px) {
      padding-bottom: 30px;
    }
}