.static-pages {

    padding: 156px 0 120px 0;
    
    h1 {
        color: $white;
        font-size: $font-size-large-xxx; //56px
        font-weight: 500;
        margin-bottom: 1.250rem; //20px
        line-height: 84px;
    }

    h2 {
        color: $white;
        font-size: $font-size-x-large; //40px
        font-weight: 500;
        margin-bottom: 1.250rem; //20px
    }

    h3 {
        color: $white;
        font-size: $font-size-large-x; //32px
        font-weight: 500;
        margin-bottom: 1.250rem; // 20px
    }

    h4 {
        color: $white;
        font-size: $font-size-large; //24px
        font-weight: 500;
        margin-bottom: 1.250rem; //20px
    }

    h5 {
        color: $white;
        font-size: $font-size-x-medium; //20px
        font-weight: 500;
        margin-bottom: 1.250rem; //20px
    }

    p {
        color: $white;
        font-size: $font-size-regular-x; //18px
        font-weight: 400;
        line-height: 2.25rem; //36px
        margin-bottom: 2.5rem; //40px

        a {
            color: $white;
        }
    }

    li {
        color: $white;
        font-size: $font-size-regular-x; //18px
        font-weight: 400;
        line-height: 2.25rem; //36px  
    }
}

.mb-120 {
    margin-bottom: 7.5rem !important; //120px
}

.mb-60 {
    margin-bottom: 3.75rem !important; //60px
}

.mb-40 {
    margin-bottom: 2.5rem !important; //40px
}

.mb-20 {
    margin-bottom: 1.250rem !important; //20px
}

#termsOfUse {
    .breakAll {
        word-break: break-all;
    }
}

#contactUs{
    padding-top: 110px !important;
    .form-control{
        border-radius: 6px;
        border: 1px solid $text-light-primary;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
        color: $text-dark-tertiary;

        &::placeholder{
            color: $text-dark-tertiary;
        }
    }

    textarea{
        resize: none !important;
        height: 173px !important;
    }

    .iti{
        display: block;
    }

    .preferences-tag{
        .form-check-input{
            &:checked{
                &+.form-check-label{
                    background-color: $cta-light-primary-hover !important;
                    color: $white !important;
                }
            }
        }
    }

    input[type=checkbox]{
        width: 24px;
        height: 24px;
        border: 1px solid $stroke-dark !important;
        background: $background-dark-tertiary !important;
        appearance: none !important;
    
        &:checked{
            background-image: url(https://av-eks-newhomepage.s3.ap-south-1.amazonaws.com/images/icon/check.svg) !important;
            background-repeat: no-repeat !important;
            background-position: center center !important;
        }
    }
    .form-check-label{
        color: white !important;
        opacity: 1 !important;
    }

    .breakAll {
        word-break: break-all;
    }
}