.leaderboard-card{
    .btn{
        width: fit-content !important;
    }

    table{
        td{
            padding: 16px 10px;
        }

        tr{
            &.active{
                border-top: 1px solid transparent;
                border-bottom: 1px solid transparent;
                .leaderboard-avtar-wrapper{
                    border: 1px solid transparent;
                    background: linear-gradient($background-dark-tertiary, $background-dark-tertiary) padding-box,
                    $my-gradient border-box;
                    z-index: 1;
                }
                background: linear-gradient($background-dark-tertiary, $background-dark-tertiary) padding-box,
                $my-gradient border-box;
            }
        }
    }
} 

#problemStatement,#mySolution{
    table{
        tr,thead{ 
            border-bottom:1px solid $stroke-dark;

            td{
                padding: 16px;
            }

            &:last-child{
                border: 0px;
            }
        }
    }
}

#mySolution{
    .toast{
        background-color: rgba(249, 200, 35, 0.10) !important;
        border: 0px !important;
        width: 100% !important;
        max-width: 100% !important;
    }
}

#detailSection{
    .sticky-top{
        top: 100px !important;
    }

    @media (max-width:767px) {
        padding-top: 24px !important;
    }
}

#timelines,#rewards,#winner{
    img{
        max-width: 100%;
        height: auto;
    }
    h2{
        font-size: 1.5rem;
        color: $white;    
        margin-bottom: 1rem !important;
    }
    h3{
        font-size: 1.25rem;
        color: white;
        margin-bottom: 1rem !important;
    }
    p{
        font-size: 1.125rem;
        color: white;
        margin-bottom: 1rem !important;
    }
    ul{
        font-size: 1.125rem;
        color: white;
        margin-bottom: 1rem !important;
    }
}