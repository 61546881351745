.footer {
    clear: both;
}

.whatsapp-floating {
    position: fixed;
    bottom: 60px;
    right: 20px;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    z-index: 100000;
    text-align: center;
    background: #5bc255;
    border: 5px solid #fff;
    width: 60px;
    height: 60px;
    display: flex;
    align-self: center;
    align-items: center;
}

.whatsapp-floating:hover,
.whatsapp-floating:focus {
    color: #fff;
}

.whatsapp-floating img {
    max-width: 100%;
    width: 25px;
    margin: 2px auto;
    display: table;
}

@media (max-width: 767px) {
    .whatsapp-floating {
        bottom: 10px !important;
    }
}