#heroContent {
    height: 100vh;
    position: relative;
    @media (max-width:991px) {
        background-image: none !important;
        background-color: $background-dark-primary;
        
    }

    .fs-18 {
        @media (max-width:480px) {
            font-size: $font-size-regular !important;
        }
    }
    
    h1,h2{
        font-weight: $font-weight-medium-x;
        letter-spacing: 0.07rem;
        @media (max-width:1199px) {
            font-size: $font-size-large-xx;
        }

        @media (max-width:480px) {
            font-size: $font-size-large-x;
        }

        span{
            -webkit-text-fill-color:initial;
        }
    }
    .btn-dark-primary {
        padding: 12px 18px;
    }

    h3 {
        line-height: 30px;
        @media (max-width:599px) {
            font-size: $font-size-x-regular;
        }
    }
    video{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        object-fit: cover;

        @media (max-width:991px) {
            display: none;
        }
    }
}

#number {
    padding: 3.75rem 5rem;
    gap: 0.625rem;
    @media (max-width:599px) {
        padding: 2.75rem 1rem !important;
    }

    .row {
        @media (max-width:991px) {
            position: relative;
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 30px;
                height: 30px;
                transform: translate(-50%, -50%);
                @include border-radius(30px);
                background-color: $background-dark-secondary;
            }
        }  
    }

    h2 {
        letter-spacing: 4px;
    }
    h3 {
        color:$text-dark-secondary;
        @media (max-width:599px) { 
            font-size: $font-size-regular-x;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: pre-line;
        }
    }
    .col-sm-6 {
        @media (max-width:991px) {
            padding: 0px;
        }
        .number-box {
            border-right: solid 1px $stroke-dark;
            @media (max-width:991px) { 
                padding: 15px 0;
                height: 130px;
            }

            &.px-5 {
                @media (max-width:420px) { 
                    padding: 15px 0px !important;
                }
            }
        }

        &:last-child {
            .number-box {
                border-right: 0px;
            }
        }
        &:nth-child(2) {
            .number-box {
                @media (max-width:991px) { 
                    border-right: 0px;
                    border-bottom: solid 1px $stroke-dark;
                }
              
            }
        }
        &:nth-child(1) {
            .number-box {
                @media (max-width:991px) { 
                    border-bottom: solid 1px $stroke-dark;
                }
              
            }
        }
    
        @media (max-width:599px) {
            flex: 0 0 50% !important;
            max-width: 50% !important;
        }
    }
    .row{
        @media (max-width:599px) {
            align-items: center;
            justify-content: center;
        }
        &.gx-4{
            @media (max-width:599px) {
                padding: 0px !important;
                --bs-gutter-x:0px !important
            }
        }
    }
}

#businessLogo {

    .heading-with-text{
        p{
            padding: 0px 5rem;
            margin-bottom: 3.75rem;
            @media (max-width:599px) {
                padding: 0 15px;
            }
        }
    }

    .company-image-wrapper{
        width: 160px;
    }

    .swiper-wrapper{
        transition-timing-function: linear;
    }
}

#circleDiagram{
    .heading-with-text{
        @media (max-width:1199px) {
            margin-bottom: 30px;
        }
    }

    img {
        &.ms-5 {
            @media (max-width:1199px) {
                margin: 0px !important;
            } 
        }
        @media (max-width:991px) {
            display: none;
        }
    }

    .mobile-circle {
        display: none;
        @media (max-width:991px) {
            display: block;
        }
    }

    ul {
        position: relative;
        margin: 0 0 0px 22px;
        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 4px;
            height: calc(100% / 8 * 7);
            background: $white;
        }
        li {
            list-style: none;
            margin-bottom: 30px;

            .learn-container {
                position: relative;
                .icon-box{
                    position: absolute;
                    left: -60px;
                    top: 0px;
                    width: 60px;
                    height: 60px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    background: $background-dark-tertiary;
                }
                .icon-body {

                    h4 {
                        font-size: $font-size-regular-x;
                    }
                }
            }
        }
    }
}

#experience{
    background-color: $background-dark-primary;

    #experienceTabs{
        margin-top: 3.75rem;
        
        .nav-link,a[data-bs-toggle=collapse]{
            color: $text-dark-tertiary;
            border-bottom: 1px solid $stroke-dark;
            border-radius: 0px;
            transition: all ease-in-out 0.3s;
            
            &:last-child{
                border: 0px;
            }

            p{
                display: none !important;
                @media(max-width:991px) {
                    display: block !important;
                    margin: 0px;
                }
            }

            &:has(+ .active) {
                border:0px;
            }

            &.active,&[aria-expanded=true]{
                background-color: $blue;
                color: $white !important;
                border-radius: 0.5rem;

                p{
                    display: block !important;
                }
            }
        }

        a[data-bs-toggle=collapse]{
            position: relative;
            &::after{
                content: '';
                width: 10px;
                height: 10px;
                border-width: 0px 0px 2px 2px;
                border-style: solid;
                border-color: $white;
                position: absolute;
                transform: rotate(-45deg);
                top: calc(50% - 5px);
                right: 20px;
                transition: all ease-in-out 0.3s;
            }

            &[aria-expanded=true]{
                &::after{
                    transform: rotate(135deg);
                }
            }
        }

        .tab-content{
            .card{
                .icon-wrapper{
                    width: 3rem;
                    height: 3rem;
                }

                p{
                    color: $text-dark-tertiary;
                }
            }
            &.background-dark-secondary {
                @media(max-width:991px) {
                    background: transparent !important;
                }
            }
        }

        .nav-pills {
            display:none;
        }
        
        @media(min-width:991px) {
            .nav-pills {
                display: flex;
            }

            .card {
                margin-bottom: 20px;
                background: $background-dark-secondary !important;
                background-color: $background-dark-secondary !important;
                @include border-radius(30px);
                .card-header {
                    display:none;
                }
                .collapse{
                    display:block;
                }
            }
        
        }
        
        @media(max-width:991px){
            .tab-pane {
                display: block !important;
                opacity: 1;
                margin: 0 15px 15px 15px;
                &.bg-transparent {
                    background: $background-dark-secondary !important;
                    background-color: $background-dark-secondary !important;
                }
            }
        }
    }
}

.tab-content 

#aiJourney{
    .card{
        p{
            color: $text-dark-tertiary;
        }
    }
}

#personalised{
    .card{
        p{
            color: $text-dark-tertiary;
        }
    }
}

#compete{
    .company-image-wrapper{
        width: 5rem;
    }
}

#experienceEvents{
    padding-bottom: 120px !important;
    .company-image-wrapper{
        width: 5rem;
    }
}

#hearFromOurCommunity{
    p{
        line-height: 2.25rem !important;
    }

    .swiper-slide{
        width: 40% !important;
        @media (max-width:769px) {
            width: 80% !important;
        }
    }

    .card-body{
        height: 180px !important;
        &.custom-quote{
            height: 300px !important;
        }
    }

    .card-footer{
        border-top:1px solid $stroke-dark !important;
        @media (max-width:599px) {
            flex-direction: column !important;

            .ms-auto{
                margin: 0px !important;
            }

            p{
                text-align: center !important;
            }

            .avtar{
                margin-right: 0px !important;
                margin-bottom: 10px !important;
            }
        }
    }
}

#discoverAi{
    .card-image-wrapper{
        aspect-ratio: 2/1;
    }
}

#banner{
    .swiper-pagination {
        bottom: 20px !important;
    }

    .swiper-pagination-bullet{
        width: 10px !important;
        height: 10px !important;
        background-color: $white !important;
        opacity: 1 !important;

        &.swiper-pagination-bullet-active-main{
            width: 40px !important;
            @include border-radius(30px);
        }
    }
}

#homepageModal{
    .modal-dialog{
        min-height: 100vh !important;
        height: 100%;

        @media (max-width:1400px) {
            height: auto !important;
        }
    }

    .text-gradient{
        -webkit-text-fill-color:transparent;
    }

    h1{
        @media (max-width:599px) {
            text-align: left !important;
        }
    }
    h2{
        @media (max-width:599px) {
            text-align: left !important;
        }
    }
    ul{
        @media (max-width:599px) {
            text-align: left !important;
        }
    }
}