.login-modal{
    .modal-dialog{
        .modal-content{
            max-width: 424px !important;
            min-height: 498px !important;

            input[type=checkbox]{
                width: 24px;
                height: 24px;
                border: 1px solid $stroke-dark;
                background: $background-dark-tertiary;

                &:checked{
                    background-image: url(https://av-eks-newhomepage.s3.ap-south-1.amazonaws.com/images/icon/check.svg);
                    background-repeat: no-repeat;
                    background-position: center center;
                }
            }

            .form-control{
                border-radius: 6px;
                border: 1px solid $text-light-primary;
                box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
                color: $white;

                &::placeholder{
                    color: $text-dark-tertiary;
                }
            }
        }
    }
}

.modal-backdrop,.tox-dialog-wrap__backdrop{
    background: rgba(255, 255, 255, 0.20) !important;
    backdrop-filter: blur(4px) !important;
}

.team-creation-modal {
    &.lead-modal .modal-dialog .modal-content{
        padding: 0px !important;
    }

    #teamAvtarInput{
        border: 1px solid $stroke-dark;

        .team-avtar-wrapper,.avtar-image-wrapper{
            border: 1px solid $stroke-dark;
            overflow: hidden;
        }

        &.error{
            border:1px solid $red !important;

            path{
                stroke: red;
            }
        }
    }

    #teamNameInput{
        &.error{
            border:1px solid $red !important;

            path{
                stroke: red;
            }
        }
    }

    .team-member-list{
        li{
            border-bottom: 1px solid $stroke-dark;
        }
        height: 278px;
        overflow: auto;

    }

    .teamNameInputBox{
        border: 1px solid $stroke-dark;
    }

    .loader{
        min-height: 294px;
        display: none !important;

        &.active{
            display: flex !important;
        }

        .loader-svg-wrapper{
            // animation: rotateAnimation 5s linear infinite;
        }
    }
}

#hackathonAddSolutionModal{
    .modal-body{
        min-height: 318px;
    }

    .loader{
        min-height: 294px;
        display: none !important;

        &.active{
            display: flex !important;
        }

        .loader-svg-wrapper{
            // animation: rotateAnimation 5s linear infinite;
        }
    }

    #codeFileProgressBarWrapper,#submissionFileProgressBarWrapper{
        display: none;

        &.active{
            display: flex;
        }

        .progress-bar{
            height: 8px;
    
            .gradient-progress-bar{
                background:$my-gradient;
                width: 0px;
            }
        }
        &.danger{
            border:1px solid $red;
        }
    }

    #labelCodeFileProgress,#labelSubmissionFileProgress{
        &.danger{
            border: 1px solid red;

            .code-file-wrapper{
                background-color: rgba(240, 68, 56, 0.20) !important;

                svg path{
                    fill: red !important;
                }
            }
        }
    }

    #showCodeFileProgress,#showSubmissionFileProgress{
        display: none;

        &.active{
            display:flex;
        }

        &.danger{
            border: 1px solid $red;

            .file-svg-wrapper{
                background-color: rgba(240, 68, 56, 0.20) !important;

                svg path{
                    fill: red !important;
                }
            }

            p:first-child{
                color:$red;
            }
            .uploaded-svg-wrapper{
                svg path{
                    stroke: red !important;
                }
            }

        }
    }
    

    .uploaded-svg-wrapper{
        display: none;
    }

    #codeFileReuploadIcon,#submissionFileReuploadIcon{
        display: none;
    }
}

@keyframes rotateAnimation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  