#genai{
    #firstFold{
        .text-gradient{
            background: var(--brand-colors-primary-gradient, linear-gradient(90deg, #D22D1E -0.08%, #963AB1 52.26%, #20469B 99.82%)) !important;
            -webkit-background-clip: text !important;
            -webkit-text-fill-color:transparent;
            
        }
    
        h2{
            width: fit-content;
        }
    
        .enroll-number{
            margin-top: 60px !important;
        }

        ul{

            @media (max-width:768px) {
                text-align: left !important;
                padding-left: 0px !important;
            }
        }
    }
    
    .right-shap {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
    }

    .btn-slider-group{
        width: fit-content;
    }

    #certification{
        margin-top: 120px;

        .certificate-border{
            border-color: rgba(255,255,255, 0.50) !important;
        }
    }

    #chooseLearn .accordion{
        height: 400px;
    }

    .company-image-wrapper {
        @media (max-width:1199px) {
            padding-top: 1rem;
        }
    }
}

#freeTrial{
    .card{
        max-width: 520px !important;
        background-color: transparent !important;
    }
}