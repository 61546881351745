@import 'var';
@import 'colors';
@import 'error_404';
@import 'homepage/homepage';
@import 'blackBelt/blackBelt';
@import 'header';
@import 'footer';
@import 'modal';
@import 'homepage/static_pages';
@import 'homepage/about-us';
@import 'cookies';
@import 'homepage/careers';
@import 'admin';
@import 'genaipinnacle/genaipinnacle';
@import 'freecourses/freecourses.scss';
@import 'datahack/datahack';
@import 'datahack/competition_listing';
@import 'datahack/event_detail';
@import 'rakshak/rakshak';
@import 'datahack/hackthon_detail';
@import 'datahack/leaderboard';
@import 'datahack/skilltest_lb_modal';
@import 'datahack/skill_test';
@import 'b2b/landingpage';
@import 'fractal/fractalhome';
@import 'agenticai/agenticai';

body{
    font-family: 'Inter', sans-serif;
    letter-spacing: 0.32px;
}

#commingSoon{
    min-height: 47.4vh;
}

// textarea{
//     padding: 14px;
//     border: 0px;
//     color: $white !important;
//     height: 500px !important;
//     background-color: $background-dark-primary;
//     resize: none !important;
// }

.language{
    position: absolute;
    background-color: $text-dark-secondary;
    padding: 5px;
    font-size: 13px;
    color: $white;
    font-weight: 800;
    bottom: 5px;
    right: -1px;
    border-radius: 5px;
}

.bg-size-cover {
    background-size: cover !important;
}

.bg-repeat-0 {
    background-repeat: no-repeat !important;
}

.bg-position-center {
    background-position: top center !important;
}
.bg-transparent {
    background-color: transparent !important;
    background: transparent !important;
}

.text-gradient {
    background: var(--brand-colors-primary-gradient, linear-gradient(90deg, #D22D1E 37.08%, #963AB0 62.26%, #20469B 99.82%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.my-text-gradient {
    background: var(--Brand-Colors-primary-gradient, linear-gradient(90deg, #d22d1e -.08%, #963ab1 52.26%, #20469b 99.82%, #20469b 99.82%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.common-style {
    padding-top: 120px;
    @media (max-width:991px) {
        padding-top: 91px;
    }
}

.common-style-py {
    padding-top: 60px;
    padding-bottom: 100px;
    @media (max-width:991px) {
      padding-top: 30px;
      padding-bottom: 40px;
    }
}  

.heading-with-text {
    p {
        line-height: 26px;
    }

    .fs-40 {
        @media (max-width:1199px) {
            font-size: 28px !important;
            line-height: 40px;
        }
       
    }
   
}
.text-blue {
    color: $blue !important;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  
  .flash-strip {
    background: var(--brand-colors-primary-gradient, linear-gradient(90deg, #D22D1E -0.08%, #963AB1 52.26%, #20469B 99.82%));
    @media (max-width:991px) {
      font-size:0.9rem !important;
    }
    .fs-20 {
      @media (max-width:991px) {
        font-size:0.9rem !important;
      }
    }
  }
  

// all btn
.btn {
    &.btn-dark  {
        &.btn-dark-primary {
            background-color: $cta-dark-primary !important;
            border: 1px solid $stroke-dark;
            padding: 12px 24px !important;
            &:hover,
            &:focus,
            &.hover {
                background-color: $my-gradient !important;
                background: $my-gradient !important;
                border-color:$my-gradient !important;
            }
        }
    }

    &.btn-primary{
        padding: 12px 24px !important;
    }

    &.play-btn{
        outline: 1px solid $white;
        background-color: rgba($color: $white, $alpha: 0.6);
        backdrop-filter: blur(3.375px);
        width: 65px;
        height: 65px;
        top: calc(50% - 32.5px);
        left: calc(50% - 32.5px);
        outline-offset: 3px;
    }
    &.video-play-btn {
        outline: 1px solid $white;
        background-color: rgba($color: $white, $alpha: 0.6);
        backdrop-filter: blur(3.375px);
        padding: 16px 18px;
        top: calc(50% - 32.5px);
        left: calc(50% - 32.5px);
        outline-offset: 3px;

        
        span {
            display: block;
            position: relative;
            z-index: 3;
            width: 0;
            height: 0;
            border-left: 12px solid $white;
            border-top: 8px solid transparent;
            border-bottom: 8px solid transparent;
        }
    }
    
}

.btn, .video-play-btn.sm {
    padding: 8px 8px !important;
    span {
        border-left: 7px solid $white !important;
        border-top: 5px solid transparent !important;
        border-bottom: 5px solid transparent !important;
    }
}

.bookmark-btn{
    background-color: $blue !important;
    width: 2.5rem;
    height: 2.5rem;
    bottom: 15px;
    right: 10px;
}

.list-style-none{
    list-style: none !important;
    padding: 0px;
}


.common-card-style {
    .btn {
        &.btn-secondary {
            background-color: #272528 !important;
        }
    }

    h2 {
        line-height: 30px;
    }

    .swiper-slide {
        height: auto !important;
    }

    .line-height-60 {
        line-height: 60px;
        @media (max-width:1199px) {
            line-height: inherit !important;
        }
    }

    .card{
        img{
        transition: all ease-in-out 0.3s;
        }
        &:hover{
            img.card-img-top{
                transform: scale(1.2);
            }
            .btn-dark-primary {
                background-color: $my-gradient !important;
                background: $my-gradient !important;
                border-color:$my-gradient !important;
            }
        }
    }

    .card-img{
        aspect-ratio: 2/1;
    }
}

.custom-quote{
    &::after{
        content: '“';
        opacity: 0.1;
        color: $white;
        font-size: 118px;
        font-weight: $font-weight-medium-x;
        position: absolute;
        left: 10px;
        top: -17px;
    }
}

.btn-gradient {
    background: $my-gradient !important;
    background-image: $my-gradient !important;
    transition: all .4s ease-in-out;
    &:hover {
        background:linear-gradient(279deg, #D22D1E -0.08%, #963AB1 52.26%, #20469B 99.82%) !important;
        background-image:linear-gradient(279deg, #D22D1E -0.08%, #963AB1 52.26%, #20469B 99.82%) !important;
        transition: all .4s ease-in-out;
    }
}

.iti{
    width: 100%;
}

.iti--container{
    @media (max-width:1200px) {
        left: 0px !important;
        padding: 0px 20px !important;
    }
}

.iti__country-list {
    max-width: 285px;
    @media (max-width:1200px) {
        max-width: calc(100% - 40px);
    }
    li span {
        color: $cta-dark-primary;
    }
}

.pointer{
    cursor: pointer !important;
}

#comingSoon{
    background-color: black !important;
    height: calc(100vh - 535px);
    background: url("https://imgcdn.analyticsvidhya.com/homepage/coming_soon.png");
    background-size:100% auto;
    background-repeat: no-repeat;
    background-position: bottom;
}

.no-pointer{
    cursor:default !important;
}

.offer-sticky{
    background-color: $cta-light-primary-hover;
    padding: 12px 60px;
    z-index: 9999 !important;

    &.is-gradient{
        background: $my-gradient !important;
    }

    .count-row{
        list-style: none !important;
        display: flex;

        li{
            display: flex;
            align-items: flex-end;

            h6{
                margin: 0px;
            }
        }
    }
    
    .btn-close{
        padding:0px !important;
        top: calc(50% - 8px) !important;
        right: 15px !important;

        @media (max-width:767px) {
            padding:1rem !important;
            top: 0px !important;
            right: 0px !important;
        }
    }

    @media (max-width:767px) {
        padding: 12px;
        .d-flex{
            p{
                font-size: $font-size-small !important;
                margin-bottom: 10px !important;
            }
        }
        .count-row {
            padding: 0px !important;
            li {
                width: fit-content !important;
                h6 {
                    font-size: $font-size-regular-x !important;
                }
                small {
                    font-size: $font-size-small-x !important;
                }
            }
        }

        .ms-auto{
            margin: 0px !important;
        }
    }
}

.object-fit-contain{
    object-fit: contain !important;
}

.icon-circle{
    width: 37px;
    height: 37px;
    border-radius: 50%;
}

iframe#webpack-dev-server-client-overlay{
    display:none!important
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-box-shadow: 0 0 0 30px $background-dark-tertiary inset !important;
    -webkit-text-fill-color: white !important;
    color: $text-dark-tertiary !important;
    caret-color: $text-dark-tertiary !important;
}

.iti__country-list{
    background-color: $background-dark-tertiary !important;
    border-color: $background-dark-tertiary !important;

    .iti__country-name,.iti__dial-code{
        color: $white !important;
    }
}

.iti__dropdown-content{
    background-color: $background-dark-tertiary !important;
    border-color: $background-dark-tertiary !important;
}

.iti__selected-flag {
    padding: 0 14px 0 15px !important;
    border-right: solid 1px $stroke-dark;
}

.iti__arrow {
    height: 8px !important;
    width: 8px !important;
    border-style: solid !important;
    border-color: $text-dark-tertiary !important;
    border-width: 0px 2px 2px 0px !important;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-left: 14px;
}


.form-control {
    &[type=tel]{
        padding-left: 85px !important;

    }
}

input[type=datetime-local]{
    &::-webkit-calendar-picker-indicator {
        filter: invert(1);
    }
}

.avtar{
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.marketing-avtar{
        width: 56px;
        height: 56px;
    }
}

.pagination{
    .page-item{
        .page-link{
            background-color: $background-dark-primary;
            border-radius: 2px;
            border: 1px solid $stroke-dark;
            margin: 0px 8px;
            color: white;

            &.active{
                background: $my-gradient !important;
                border-color:$my-gradient !important;
            }
        }
    }
}

.text-truncate-2{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines to show */
    -webkit-box-orient: vertical;
}

.cookies-based-content{
    z-index: 10000 !important;
}

.lh-38{
    line-height: 38px !important;
}


.roadmap-mobile{
    list-style: none;
    padding-left: 0px;

    li{
        padding-bottom: 23px;
        position: relative;
        color: white !important;

        &::after{
            content: '';
            position: absolute;
            border-right: 2px dashed $background-dark-tertiary;
            height: 100%;
            left:15px;
            top: 0;
        }

        &:last-child{
            padding-bottom:32px ;
            &::after,&::before{
                display: none;
            }
        }
    }
    
    .roadmap-number{
        width: 34px;
        height: 34px;
        display: inline-block;
        border: 1px solid transparent;
        background: linear-gradient($background-dark-tertiary, $background-dark-tertiary);
        z-index: 1;

        &.active{
            background: linear-gradient($background-dark-tertiary, $background-dark-tertiary) padding-box,
            $my-gradient border-box;
        }

        &.small-circle{
            width: 25px;
            height: 25px;
        }
    }
}

input.radio-style{
    &[type="radio"]{
        &:checked,&:not(:checked){
            position: absolute;
            left: -9999px;

            & + label{
                position: relative !important;
                padding-left: 28px !important;
                cursor: pointer !important;
                display: inline-block !important;
                background-color: transparent !important;
                
                &::before{
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 20px;
                    height: 20px;
                    border-radius: 100%;
                    background: $my-gradient;
                }

                &::after{
                    content: '';
                    width: 16px;
                    height: 16px;
                    border: 3px solid $background-dark-primary;
                    background: $my-gradient;
                    position: absolute;
                    top: 2px;
                    left: 2px;
                    border-radius: 100%;
                    -webkit-transition: all 0.2s ease;
                    transition: all 0.2s ease;
                }
            }
        }

        &:not(:checked){
            & + label{
                &::before{
                    background: $background-dark-tertiary;
                    border: 2px solid $text-dark-tertiary;
                }

                &::after{
                    opacity: 0;
                    -webkit-transform: scale(0);
                    transform: scale(0);
                }
            }
        }
    }
}

.btn-green{
    background-color: rgba(133, 217, 146, 0.20);
    color: $green !important;

    svg path{
        stroke: $green !important;
    }
}

.btn-red{
    background-color: rgba(240, 68, 56, 0.20);
    color: $red !important;

    svg path{
        stroke: $red !important;
    }
}

.btn-yellow{
    background-color: rgba(249, 200, 35, 0.20);
    color: $orange!important;

    svg path{
        stroke: $orange !important;
    }
}

.html-field{
    p,a,span{
        font-size: 18px !important;
        font-weight: 400 !important;
        color: $white !important;
        margin-bottom: 0px !important;
        line-height: 28px !important;
    }
    h2{
        font-size: 24px;
        font-weight: 500;
        color: $white !important;
    }
    ul{
        font-size: 18px !important;
        color: $white !important;
        font-weight: 400 !important;
    }

    table {
        &.pkg-table{
            background-color: transparent !important;
            --bs-table-bg: transparent !important;
            td{
                font-size: 18px !important;
                color: $white !important;
                font-weight: 400 !important;
                margin: 20px 0px !important;
            }
        }
    }

    img,iframe{
        max-width: 100% !important;
        height: auto !important;
        aspect-ratio: 2/1 !important;
        margin: 20px 0px !important;
    }
}

.underline-bold{
    width: 97px;
    height: 7px;
    background-color:$text-light-primary;
}

.arrow-right {
    background: $white;
    height: 2px;
    width: 15px;
    position: absolute;
    cursor: pointer;
    top: 15px;
    margin-left: 5px;
    transform: translate(5px, 0px);
    &::before,
    &::after {
        content: "";
        background: $white;
        position: absolute;
        height: 2px;
        width: 7px;
    }
    &:before {
        right: -3px;
        bottom: -2px;
        transform: rotate(-45deg);
      }
    
      &:after {
        right: -3px;
        top: -2px;
        transform: rotate(45deg);
      }
}

#avloader{
    z-index: 9999999 !important;
}

input.form-control.otp-input{
    padding-left: 12px !important;
}

.loader-image-center {
    left: calc(50% - 75px);
    top: calc(50% - 75px);
}

#supportchatwidget .circular-widget .logo-img-icon{
    z-index: 9999999 !important;
}

#learnersTestimonial{
    .custom-quote{
        padding-top: 50px !important;
    }
}

.mb-6{
    margin-bottom: 4rem !important;
}

.email-text{
    overflow-wrap: anywhere !important;
}

.comment-box .tox,.comment-box .tox *{
    background-color: transparent !important;
    border:0px !important;
    box-shadow: none !important;
    outline: none !important;

    svg{
        fill: white !important;
    }

    .tox-statusbar__text-container.tox-statusbar__text-container--flex-end{
       justify-content: start !important;
    }
    .tox-statusbar__right-container *{
        color: white !important;
    }
    ::placeholder {
        color: white !important;
    }

    .tox-editor-header{
        border-bottom: 1px solid #6c757d !important;
    }
}

textarea#commentTitle{
    &::placeholder {
        color: white !important;
        font-size: 18px !important;
        font-weight: 500 !important;
    }
}

.discussion-dropdown {
    .dropdown-menu{
        border: 1px solid $stroke-dark !important;
    }
    right: 10px !important;
}

.filter-row{
    svg{
        top: calc(50% - 8px);
        left: 30px;
    }
    .ps-5{
        padding-left: 40px !important;
    }

    .input-group{
        border: 1px solid $text-light-primary !important;
        border-radius: 6px;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);


        .form-control{
            color: $text-dark-tertiary;

            &::placeholder{
                color: $text-dark-tertiary;
            }
        }
    }
}

.tox .tox-edit-area::before{
    display: none !important;
}