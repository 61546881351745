#instruction{
    .icon-row{
        border-top: 1px solid $stroke-dark !important;
        border-bottom: 1px solid $stroke-dark !important ;
    }
}

#testScreen{
    z-index: 9999 !important;

    .border-gradient{
        background: $my-gradient;
        padding: 1px 0px 1px 1px !important;
    }

    .question-progress{
        max-width: 362px;
        width: 362px;
        height: 8px;
        background:$stroke-dark;

        .gradient-bar{
            background: $my-gradient;
        }
    }

    .option-group{
        label{
            background-color: $background-dark-secondary !important;
            padding: 24px 32px 24px 64px !important;

            &::before{
                width: 24px !important;
                height: 24px !important;
                top: calc(50% - 12px);
                left: 32px;
            }
            &::after{
                width: 20px !important;
                height: 20px !important;
                top: calc(50% - 10px);
                left: 34px;
            }
        }
        .options{
            padding: 24px 32px 24px 32px !important;
        }
    }

    .toast{
        border: 0px !important;
    }

    .btn-green,.btn-red{
        width: 25px !important;
        height: 25px !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .timer-wrapper{
        p{
            width: 52px;
            text-align: right;
        }
        span{
            width: 55px;
            text-align: right;
        }
    }

    #feedbackBtn{
        @media (max-width:599px) {
            display: none !important;
        }
    }
}

#camera,.modal{
    z-index: 999999 !important;
}

#feedbackModal{
    textarea{
        resize: none;
    }
}