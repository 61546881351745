#cookiesModal{
    .modal-dialog{
        max-width: 700px !important;
        max-height: 700px !important;
        height: 700px !important;
        width: 100%;

        .nav-tabs{
            border-bottom: 1px solid $stroke-dark !important;
        }
        .nav-item{
            width: calc(100% / 3);

            .nav-link {
                background-color: transparent;
                border: 0px;
                color: $white;
                border-bottom:2px solid transparent ;
                &.active{
                    border-color: $white;
                }
            }
        }
        .accordion-item{
            border: 0px;
            background: $background-dark-secondary !important;
            
            .accordion-button{
                background: $background-dark-secondary !important;
                color: white;

                h6{

                    position: relative;
                    &::before{
                        content: "";
                        width: 10px;
                        height: 10px;
                        border-width:2px ;
                        border-color:$white $white transparent transparent;
                        background-color: transparent;
                        border-style: solid;
                        position: absolute;
                        right: 0;
                        top: 2px;
                        transform: rotate(135deg);
                        transform-origin: center;
                        transition: all ease-in-out 0.3s;
                    }
                }

                &:not(.collapsed){
                    h6{
                        &::before{
                            transform: rotate(-45deg);
                        }
                    }
                }

                &:active,&:hover,&:focus{
                    box-shadow: none !important;
                }
            }

            .accordion-collapse{
                background: $background-dark-secondary !important;
            }
        }

        .accordion-body{
            .accordion-item{
                border: 1px solid var(--strokes-dark-primary, #3C3A3D) !important;
                margin-bottom: 32px;
                padding: 24px 16px !important;
                border-radius: 8px;

                .accordion-button {
                    padding: 0px;
                }
            }
        }

        .accordion-body,.accordion-collapse,.accordion-button,.accordion-header{
            outline: 0px !important;
            box-shadow: none !important;
            &::after{
                display: none !important;
            }
        }

        .card{
            background-color: $background-dark-tertiary !important;
            color: white;
            padding: 24px 16px !important;

            &-body{
                padding: 0px;
            }

            &-footer{
                border-color: $stroke-dark;
                padding: 0px;
                p{
                    margin: 5px 0px;
                }
            }
        }
        
        .modal-footer{
            .btn{
                @media (max-width:599px) {
                    width: 100%;
                }

                &.me-3{
                    @media (max-width:599px) {
                       margin: 10px 4px 4px 4px !important;
                    }
                }
            }
        }
    }

    #details{
        height: 400px;
        overflow: auto;
    }
}
  
#Cookies{
    .d-flex{
        margin-top: 10px;
    }
    .btn{
        padding: 6px 8px !important;
    }
}