#error{
    background: url("https://www.analyticsvidhya.com/static/media/hero-image-bb.a132de9e5cd9abaef9f3.png");
    background-size: cover;
    background-repeat: no-repeat;
    h1{
        font-size: 166px !important;
        @media (max-width:599px) {
            font-size: 100px !important;
        }
    }

}

.toast{
    top: 110px;
    max-width: 646px;
    width: 100%;
    right: 20px;
    border-bottom: 8px solid $red !important;
    z-index: 9999999 !important;

    &.toast-success{
        border-bottom: 8px solid $green !important;
    }

    @media (max-width:599px) {
        right: initial;
        margin: 0px auto;
    }

    .error-icon{
        background-color:rgba($red,0.2);
    }

    .success-icon{
        background-color:rgba($green,0.2);
    }

    .toast-body{
        @media (max-width:599px) {
            font-size: $font-size-small;
        }
    }
}