#tabs{
    #myListingTab{
        .nav-link{
            color: white;
            background-color: $background-dark-tertiary;
            margin-right:20px;
            border-radius: 8px;
            border: 0px;
            padding: 10px 20px !important;

            &.active{
                background-color: $my-gradient;
                background: $my-gradient;
            }
        }
    }

    .btn-dark-primary{
        background-color: $background-dark-tertiary;
        padding: 10px 20px !important;
    }

    .tournament-select{
        padding: 12px;
    }
    .tournament-select-svg{
        margin-top: 20px !important;
    }
}

#heroEventListing {
    @media (max-width:767px) {
        padding-bottom: 24px !important;
    }
}