#careers {
    h2 {
        @media (max-width:768px) {
            font-size: $font-size-large-x1;
            line-height: 42px;
        }
    }
    .career-common-style {
        padding: 60px 0;
        
        @media (max-width:768px) {
            padding: 40px 0;
        }
    }
}

#careersHeading {
    h1 {
        @media (max-width:768px) {
            font-size: $font-size-x-large;
            line-height: 42px;
        }
    }
}

#careersSwiper {
    .swiper-wrapper{
        transition-timing-function: linear;
    }
}

#joinUs {
    .card {
        padding: 24px 40px;
        
        h4 {
            margin-bottom: 1rem; //16px
        }

        p {
            font-size: $font-size-regular; // 16px
            line-height: 1.5rem; //24px
        }
        .icon-wrapper {
            width: 3rem;
            height: 3rem;
        }
    }
}

#openPositions {
    .card {
        padding: 24px 40px;
        transition: all 0.3s ease-in-out;

        h5 {
            margin-bottom: 1rem; //16px
        }

        p {
            font-size: $font-size-regular; // 16px
        }
        &:hover {
            border: 1px solid $blue;
        }
    }
}

#coreValues {
    h2 {

        &.heading-for-desktop{
            display: block !important;
            @media (max-width:768px) {
                display: none !important;
            }
        }
        &.heading-for-mobile{
            display: none !important;
            @media (max-width:768px) {
                display: block !important;
                text-align: center;
            }
        }
    }

    .swiper-slide{
        height: auto !important;
    }
}

#hiringProcess {
    .accordion {
        --bs-accordion-color: #fff;
        --bs-accordion-bg: #1A181B;
        --bs-accordion-btn-color: #fff;
        --bs-accordion-btn-bg: #151316;
        --bs-accordion-active-color: #fff;
        --bs-accordion-active-bg: #1A181B;
        --bs-accordion-btn-focus-box-shadow: none;
        --bs-accordion-border-color: #3C3A3D;
    
        .accordion-button:not(.collapsed) {
            box-shadow: none;
        }

        .accordion-item:last-child .accordion-button.collapsed, .accordion-item:last-child .accordion-body{
            border-bottom: 1px solid #3C3A3D;
        }

        .accordion-header {
            margin-bottom: 0px;
        }
    }

    h2 {
        @media (max-width:768px) {
            text-align: center;
        }
    }
    .center {
        @media (max-width:768px) {
            text-align: center;
        }
    }

    .row {
        .col {
            margin-right: 110px;

            @media (max-width:768px) {
                margin-right: 0px;
                margin-bottom: 2rem; //32px
            }
        }
    }
}

#AvInNumbersCareers {

    background-color: #c8d8f0;

    h5 {
        font-size: $font-size-x-large;
    }

    ul {

        li {
            flex: 1;
            border-right: 1px solid $stroke-light-secondary; 

            &:last-child {
                border-right: none; 
            }
        }

        @media (min-width:600) and (max-width:1199px) {
            display: grid !important;
            grid-template-columns: repeat(3, 1fr);
            gap: 2em;

            li {
                &:nth-child(3n) {
                    border-right: none;
                }
            }
        }

        @media (max-width:599px) {
            display: grid !important;
            grid-template-columns: repeat(2, 1fr);
            gap: 2rem 0;
            li {
                &:nth-child(2n) {
                    border-right: none;
                }
            }
        }
    }
}

#joinOurCommunity {
    background-image: url('../../images/join-community-bg-dark-gray.png');
    background-size: contain;
    background-color: $background-dark-secondary;
    background-repeat: no-repeat;
    background-position: bottom center;

    @media (max-width:768px) {
        background-image: url('../../images/join-community-bg-dark-gray-mobile.png');
        background-position: bottom center;
        background-size: auto;
        background-repeat: no-repeat;
        background-color: $background-dark-secondary;
    }

    @media (max-width:599px) {
        background-size: contain;
    }
    .container {
        padding: 60px 0 218px 0;

    }
}