
#createQuiz{
    input[type=datetime-local]{
        &::-webkit-calendar-picker-indicator {
            filter: invert(0);
        }
    }
}

#heroImage {
    min-height: 70vh;
    .container{
      height:70vh;

      @media (max-width:991px) {
        height: auto;
      }
    }
    
    @media (max-width:991px) {
      min-height: auto;
    }

    .text-row {
        padding: 90px 0;
    }
    ul {
        list-style-type: none;
        padding: 0;
        overflow: hidden;
        width: 500px;
        height: 97.5%;
      }
  
      li {
        position: relative;
        animation: moveUp 4s linear infinite;
        &:nth-child(2n) {
            margin-left: 20px;
        }
      }
  
      li:hover {
        animation-play-state: paused;
      }
  
      @keyframes moveUp {
        0% {
          transform: translateY(100%);
        }
        50% {
          transform: translateY(0);
        }
        100% {
          transform: translateY(-100%);
        }
      }

      .hero-slider{
        width: 424px;
        @media (max-width:991px) {
          display: none !important;
        }
      }

      .overlay-card{
        .overlay{
          display: none;
        }
        &:hover{
          .overlay{
            width: 100% !important;
            display: block;
            z-index: 99999 !important;
          }
        }
      }

      .mySwiper{
        height: 700px;

        .swiper-wrapper{
          transition-timing-function: linear;
        }
      }
}

#hearFromOurCommunity{
  .card-body.custom-quote{
    height: 400px !important;
    
    p{
      line-height: 27px !important;
    }
  }
}

#experience{

  .collapse{
    overflow: hidden;
  }
  &.event-tabs{
    .nav{
      position: relative;
      --height: 25%;
      .nav-link{
        border-bottom: 0px !important;
        border-radius: 0px !important;
        overflow: hidden;
        &.active{
          background-color: transparent !important;
          transition: all ease-in-out 3s;

          p{
            position: relative;
            animation: heightanimation 0.5s;
          }
        }
      }
      &::after{
        content: '';
        width: 2px;
        height: 100%;
        position: absolute;
        background: $background-dark-tertiary;
        left: 0;
        top: 0;
      }

      &::before{
        content: '';
        width: 2px;
        height: var(--height);
        position: absolute;
        background: $background-dark-tertiary;
        left: 0;
        transition: height 0.3s linear;
        top: 0;
        z-index: 1;
        animation: all ease-in-out 0.3s;
        background: linear-gradient(#D22D1E, #20469B);
      }
    }
    #experienceTabs 
    {
      .card{
        background-color: transparent !important;
        background: transparent !important;

        img{
          position: relative;
        }

        &-header{
          padding: 0px !important;
        }
      }

      .card.active{
        img{
          animation: imageanimation 0.9s;
        }
      }

      .tab-pane{
        margin: 0px;
      }

      a[data-bs-toggle=collapse]{
        position: relative;
        ::after{
          content: '';
          width: 2px;
          height: 100%;
          position: absolute;
          background: $background-dark-tertiary;
          left: 0;
          top: 0;
        }
        &[aria-expanded=true]{
          background-color: transparent !important;
          ::after{
            background: linear-gradient(#D22D1E, #20469B);
          }
        }
      }
    }
  }
}

@keyframes imageanimation {
  from {
    bottom: -1000px;
  }
  to {
    bottom: 0px;
  }
}

@keyframes heightanimation {
  from {
    bottom: -100px;
  }
  to {
    bottom: 0px;
  }
}

#moneyGuarantee{
  .tag-group{
    .d-inline-block{
      width: fit-content !important;
    }
  }

  &.blogathon-banner{
    img{
      @media (max-width:1200px) {
        position: static !important;
        margin-bottom: 20px;
      }
    }

    .row:hover{
      .btn{
        background:linear-gradient(90deg, #D22D1E -0.08%, #963AB1 52.26%, #20469B 99.82%) !important;
        background-image:linear-gradient(90deg, #D22D1E -0.08%, #963AB1 52.26%, #20469B 99.82%) !important;
        transition: all .4s ease-in-out;
      }
    }
  }

  &.jobathon-banner{
    .row:hover{
      .btn{
        background:linear-gradient(90deg, #D22D1E -0.08%, #963AB1 52.26%, #20469B 99.82%) !important;
        background-image:linear-gradient(90deg, #D22D1E -0.08%, #963AB1 52.26%, #20469B 99.82%) !important;
        transition: all .4s ease-in-out;
      }
    }

    .p-5{
      @media (max-width:1200px) {
        padding: 20px !important;
      }
      @media (max-width:599px) {
        padding: 30px 10px !important;
      }
    }
  }

  &.hover-state .row:hover{
    h2{
      text-decoration: underline;
    }
  }
}

.overlay{
  min-height: 100% !important;
  background-color: rgba(21, 19, 22, 0.70) !important;
  display: none !important;
  transition: display 0.3s ease-in-out;
}

#leadingWithData{
  h3{
    transition: text-decoration 0.3s ease-in-out;
  }

  .card-body{
    &:hover{
      .overlay{
        display: flex !important;
      }

      h3{
        text-decoration: underline !important;
      }
    }
  }
}

.hover-state{
  .card{
    &:hover{
      .card-text{
        text-decoration: underline;
      }
    }
  }
}

.jobathon-slider{
  height: 370px !important;
  width: 530px;
  overflow: hidden !important;
  border-radius: 24px 0px 0px 0px;


  @media (max-width:1200px) {
    position: static !important;
    width: 100% !important;
    margin-bottom: 20px;
    border-radius: 8px;
    height: 270px !important;
  }

  .mySwiper{
    height: 370px;
    width: 160px;

    @media (max-width:1200px) {
      width: 120px;
      height: 270px;
    }

    .swiper-slide{
      @media (max-width:1200px) {
        width: 120px;
      }
    }
    .swiper-wrapper{
      transition-timing-function: linear;

      .company-image-wrapper{
        @media (max-width:1200px) {
          transform: scale(0.7) translateX(-32px);
        }
      }
    }
  }
}

.head-from-our-winners#hearFromOurCommunity{
  .card-footer{
      border: 0px !important;
  }
}

.moderator-dashboard {
  .card {
    background: 
      linear-gradient(0deg, #1A181B, #1A181B), 
      linear-gradient(180deg, rgba(38, 56, 89, 0.2) 0%, rgba(31, 46, 73, 0.159375) 20.83%, rgba(0, 0, 0, 0) 100%),
      url('https://imgcdn.analyticsvidhya.com/competitions/hashtag.png') no-repeat top right !important; 

    background-size: auto;
    background-blend-mode: color; 
  }
  
  .table-info {
    .search-user {
      border: 1px solid $text-light-primary;
      width: 100%;
      max-width: 446px;
      height: 48px !important;
      .form-control{
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.04);
  
        &::placeholder{
            color: $text-dark-tertiary;
        }
      }
    }

    @media (max-width: 576px) {
      flex-direction: column !important;
      align-items: flex-start !important; 
  
      .search-user {
        margin-top: 8px; 
      }
    }
  }
}
